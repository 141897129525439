@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100svh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  user-select: none;
  
}
::-webkit-scrollbar {
  display: none;
}
body {
  
  background: #f0f0f0;
  font-family: 'Inter', sans-serif;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  align-items: center;
  position: absolute;
}

header p {
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 1.2s ease-out;
}

header p.hidden {
  opacity: 0;
}

section {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  width: 100%;
}


.section--container.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.section--container h1 {
  font-weight: 900;
  font-size: 11rem;
  letter-spacing: -6px;
  font-style: italic;
  width: 30%;
  line-height: 9rem;
  font-family: 'Nunito Sans', sans-serif;

}

.support--content {
  justify-content: end;
  padding-left: 15px;
  width: 100%;
  display: flex;
  
   
   
}

.support--content p {
  width: 350px;
  margin-bottom: 48px;
  line-height: 1.5rem;
}
.titulo{
  margin-top: 120px;
  margin-bottom: 0;
}
.parrafo{
  letter-spacing: 0;
  margin: 0;
    width: 50%;
}


.texto-label {
  cursor: pointer;
  margin-bottom: 5px;
}



.svg-label {
  position: relative;
  margin: 5px 0;
  cursor: pointer;
}

.svg-label input[type="range"] {
  position: absolute;
  width: 100px; /* Ancho de la pista */
  -webkit-appearance: none;
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
  z-index: 10; /* Asegura que el control deslizante esté por encima de otros elementos */
}
a{
  z-index: 99999;
}

.inputt{
  margin-left: 240px;
}
.logoz{
  z-index: 999999;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.logoz:hover{
  
  transform: scale(1.5);
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

button {
  margin-right: 90px;
  background-color: #dd5c18;
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: bolder;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: inset 0 0 0 0.09px black;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 10px;
  font-size: 12px;
}

button:hover {
  box-shadow: inset 250px 0 0 0 black;
  transform: scale(1.1);
}
.custon{
 
}
/* CUSTOMIZER */

.customizer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  margin-bottom: 25px;
}

.customizer span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.customizer span svg {
  width: 24px;
  padding-right: 5px;
}

.share {
  bottom: 40px;
    position: absolute;
    right: -50px;
    border-radius: 50px;
}
.zoom {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: border, color 1s ease;
}

.zoom:hover {
  color: black;
}
.tamano {
  margin-right: 3px;
  border-radius: 20px;
  border: solid 0.5px #1414147f;
  padding: 2.5px;
  transition: transform ease-in 0.3s;
}
.tamano:hover{
  transform: scale(1.2);
  background: #ffffff;}
/* PICKER */

.picker {
  position: absolute !important;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  width: 120px !important;
  height: 120px !important;
}

.color-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.decals {
  position: absolute;
  left: 50px;
  bottom: 40px;
}

.decals p {
  font-family: 'Inter var';
  font-size: 0.7rem;
  padding-bottom: 15px;
}

.decals img {
  width: 24px;
  filter: saturate(0) invert(1) brightness(5.5);
  transition: all 0.2s ease-in-out;
}

.decals img:hover {
  filter: none;
  transform: scale(1.2);
  cursor: pointer;
}

.decals--position {
  position: absolute;
  left: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 10px;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: border 1s ease;
}

.decals--position:hover {
  color: black;
  border: 1px solid #00000056;
}

.upload {
  margin-top: -5px;
  cursor: pointer;
}

.upload:hover {
  color: white;
}

.decals--container {
  gap: 20px;
}
p{
  margin: 0;
}
.como{
  color:#ffffff
}
.div {
  width: fit-content important;
}
.exit {
  position: absolute;
  top: 40px;
  right: -47px;
  background-color: black;
  box-shadow: inset 0 0 0 0.09px black;
}

.exit:hover {
  box-shadow: inset -250px 0 0 0 black;
}
.ocultar{
  display: none;
}
.extra-section {
  margin-top: 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.conteiner-estra {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding: 40px;
}
.texto-principal {
  padding-left: 5em;
}
.extra-div {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comofunciona{
  filter: drop-shadow(2px 4px 6px black);
    color: white;
    font-size: 2em;
}

@media screen and (max-width: 600px) {
  .extra-section {
    margin-top: 84px;}
  .ocultar{
    display: none;
  }
  .section--container h1 {
    font-size: 6rem;
    letter-spacing: -6px;
    line-height: 5rem;
  }
  .whast {
    font-size: 15px;
    margin-bottom: 35px;
    margin-right: 40px;
}
.share{
  font-size: 10px;
  bottom: 10px;
}
.rango {
  margin-left: -18px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}.iputt{
  margin-left: -60px;
    margin-top: 280px;
    align-items: center;
    flex-direction: column;
}
button {
  margin-right: 66px;
}
  .color-options {
    margin-bottom: 20px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }
  
 .custon {
  margin-right: 32px;
        margin-top: 60;
    }
 .titulo{
 margin-top: 140px;
 }
 
  #btncargarimagen {
    font-size: 12px;
    border-radius: 90px;
    padding: 6px 12px;
    margin-top: -5
    px;
}
  .svg-label input[type="range"] {
  rotate: 0deg;
  margin-top: -39px;
  right: -35px;
  gap: 5px;
 }
 
.decals {
  position: absolute;
  left: 12px;
}
.customizer {
 margin-bottom: 0;
}
p.parrafo{
  margin-top: 30px;
        width: 69%;
       
}

.extra-section {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.texto-principal{
  padding-left: 1em;
}
.logoz{
  width: 60px;
  height: 60px;
}
.support--content {
  padding-left: 15px;
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

}